
<template>
  <skeleton-person-general-information-global v-if="bSkeleton" />
  <div v-else>
    <!-- #region PERSONA-->
    <div class="content-identification-information">
      <p class="text-identification-information">
        Información de identificación
      </p>
      <v-container fluid>
        <v-row>
          <!-- #region LABELS AND TEXTS-FIELDS ( NOMBRE PUBLICO, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO, PAIS, ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION , DIRECCION OPCIONAL )  -->
          <!-- #region NOMBRE PUBLICO-->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">Nombre de la persona física</p>
              <p class="text-information">
                {{ oPersonOrigin.sFullName }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPerson.sFullName"
              label="Nombre de la persona física"
              placeholder="Nombre..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="50"
              @keyup="validateFormPerson()"
            >
              <template slot="label">
                <span
                  >Nombre de la persona física
                  <span class="important-data">*</span></span
                >
              </template>
            </v-text-field>
          </v-col>
          <!-- #endregion NOMBRE PUBLICO-->

          <!-- #region CORREO ELECTRONICO-->
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">Correo electrónico de la persona física</p>
              <p class="text-information" v-if="oPersonOrigin.sEmail">
                {{ oPersonOrigin.sEmail }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPerson.sEmail"
              label="Correo electrónico de la persona física"
              placeholder="Correo electrónico..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="70"
              @keyup="validateFormPerson()"
            ></v-text-field>
          </v-col>
          <!-- #endregion CORREO ELECTRONICO-->

          <!-- #region COMPONENTE GLOBAL DE TELEFONO-->
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <phone-text-component-global
              v-if="!bModify"
              :sCountryCallingCodeOrigin="
                oPersonContactoInfoOrigin.sCountryCallingCode
              "
              :sAreaCallingCodeOrigin="
                oPersonContactoInfoOrigin.sAreaCallingCode
              "
              :sPhoneNumberOrigin="oPersonContactoInfoOrigin.sPhoneNumber"
              :sPhoneExtensionOrigin="oPersonContactoInfoOrigin.sPhoneExtension"
            />
            <phone-component-global
              v-else
              :bImportantDate="false"
              @updateCountryCallingCode="updatePersonCountryCallingCode"
              :sCountryCallingCodeOrigin="
                oPersonContactoInfo.sCountryCallingCode
              "
              @updateAreaCallingCode="updatePersonAreaCallingCode"
              :sAreaCallingCodeOrigin="oPersonContactoInfo.sAreaCallingCode"
              @updatePhoneNumber="updatePersonPhoneNumber"
              :sPhoneNumberOrigin="oPersonContactoInfo.sPhoneNumber"
              @updatePhoneExtension="updatePersonPhoneExtension"
              :sPhoneExtensionOrigin="oPersonContactoInfo.sPhoneExtension"
            />
          </v-col>
          <!-- #endregion COMPONENTE GLOBAL DE TELEFONO-->

          <!-- #region PAIS-->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">País de la persona física</p>
              <p class="text-information" v-if="oPersonCountryOrigin.sName">
                {{ oPersonCountryOrigin.sName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-select
              v-else
              v-model="sCountryPerson"
              :items="aItemsCountry"
              item-text="sName"
              item-value="sCountryId"
              label="País de la persona física"
              class="global-select field-code-country"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              outlined
              @change="validateFormPerson()"
            >
            </v-select>
          </v-col>
          <!-- #endregion PAIS-->

          <!-- #region ESTADO-->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">Estado de la persona física</p>
              <p class="text-information" v-if="oPersonStateOrigin.sName">
                {{ oPersonStateOrigin.sName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-select
              v-else
              v-model="oPersonState.sStateId"
              :disabled="bDisabledStatePerson"
              :items="aItemsStatesPerson"
              item-text="sName"
              item-value="sStateId"
              label="Estado de la persona física"
              class="global-select field-code-country"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              outlined
              @change="validateFormPerson()"
            >
            </v-select>
          </v-col>
          <!-- #endregion ESTADO-->

          <!-- #region CIUDAD-->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">Ciudad de la persona física</p>
              <p
                class="text-information"
                v-if="oPersonLocationInfoOrigin.sCityName"
              >
                {{ oPersonLocationInfoOrigin.sCityName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPersonLocationInfo.sCityName"
              label="Ciudad de la persona física"
              placeholder="Ciudad de la persona física..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="40"
              @keyup="validateFormPerson()"
            >
            </v-text-field>
          </v-col>
          <!-- #endregion CIUDAD-->

          <!-- #region CODIGO POSTAL-->
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">Código postal de la persona física</p>
              <p
                class="text-information"
                v-if="oPersonLocationInfoOrigin.sZipCode"
              >
                {{ oPersonLocationInfoOrigin.sZipCode }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPersonLocationInfo.sZipCode"
              label="Código postal de la persona física"
              placeholder="Código postal..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="5"
              @keyup="validateFormPerson()"
              @keypress="fieldNumber($event)"
              @paste="noLetterZipCodePerson()"
            >
              <template slot="label">
                <span
                  >Código postal de la persona física<span class="important-data"
                    >*</span
                  ></span
                >
              </template>
            </v-text-field>
          </v-col>
          <!-- #endregion CODIGO POSTAL-->

          <!-- #region DIRECCION-->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">Dirección de la persona física</p>
              <p
                class="text-information"
                v-if="oPersonLocationInfoOrigin.sAddress"
              >
                {{ oPersonLocationInfoOrigin.sAddress }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPersonLocationInfo.sAddress"
              label="Dirección de la persona física"
              placeholder="Ej: Privada San Pedro..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="255"
              @keyup="validateFormPerson()"
            >
            </v-text-field>
          </v-col>
          <!-- #endregion DIRECCION-->

          <!-- #region DIRECCION OPCIONAL-->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">Especificación de dirección</p>
              <p
                class="text-information"
                v-if="oPersonLocationInfoOrigin.sOptionalAddress"
              >
                {{ oPersonLocationInfoOrigin.sOptionalAddress }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field
              v-else
              v-model="oPersonLocationInfo.sOptionalAddress"
              label="Especificación de dirección"
              placeholder="Ej: Piso 4, Loft 1, casa color amarillo..."
              class="global-text-field"
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              persistent-placeholder
              outlined
              maxlength="255"
              @keyup="validateFormPerson()"
            >
            </v-text-field>
          </v-col>
          <!-- #endregion DIRECCION OPCIONAL-->

          <!-- #endregion LABELS AND TEXTS-FIELDS ( NOMBRE PUBLICO, CORREO ELECTRONICO, COMPONENTE GLOBAL DE TELEFONO, PAIS, ESTADO, CIUDAD, CODIGO POSTAL, DIRECCION , DIRECCION OPCIONAL )   -->
        </v-row>
      </v-container>
      <v-divider class="divider-global mt-9"></v-divider>
    </div>
    <!-- #endregion PERSONA -->
    <!-- #region ACCIONES DE BOTONES-->
    <div class="content-btns-accions">
      <!-- #region BOTON DE ELIMINAR -->
      <div class="content-btn-second">
        <v-btn
          v-if="bModify"
          class="global-btn-second-red"
          @click="
            deleteItem(
              sTabPosition == 0
                ? oEnterprisesOrigin.sPublicName
                : oPersonOrigin.sFullName
            )
          "
        >
          Eliminar
        </v-btn>
      </div>
      <!-- #endregion -->
      <v-spacer></v-spacer>
      <!-- #region BOTON DE DESCARTAR CAMBIOS-->
      <div class="content-btn-second">
        <v-btn
          v-if="bModify"
          class="global-btn-neutral"
          @click="
            sTabPosition == 0 ? discardChangeProvider() : discardChangePerson()
          "
        >
          Descartar cambios
        </v-btn>
      </div>
      <!-- #endregion -->

      <!-- #region BOTONES DE MODIFICAR Y GUARDAR CAMBIOS-->
      <div v-show="bAdminProvider" class="content-btn-primary">
        <v-btn
          v-if="!bModify"
          :loading="bLoading"
          class="global-btn-primary"
          @click="bModify = !bModify"
        >
          Modificar
        </v-btn>
        <v-btn
          v-else
          class="global-btn-primary btn-save-change"
          :disabled="bUpdatePerson"
          @click="changePerson()"
        >
          Guardar cambios
        </v-btn>
      </div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
    <delete-component-global
      @setDialogDelete="deleteItem"
      :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete"
    />
  </div>
</template>

<script>
export default {
  name: "ProviderDetail",
  props: {
    bAdminProvider: Boolean,
  },
  data() {
    return {
      tab: 0,
      bLoading: false,
      tabsItems: {
        generalInformation: "Información general",
        purchaseOrders: "Órdenes de compra",
        rawMaterials: "Materias primas",
      },
      bSkeleton: true,
      searchProvider: 0,
      screenHeight: 0,
      heightMax: 0,
      bModify: false,
      emptyDataAddress: "Sin información.",
      bUpdateProvider: true,
      bUpdatePerson: true,
      items: ["Empresa", "Persona física"],
      aItemsCountryPhone: [
        { value: "52", text: "+52", icono: "" },
        { value: "52", text: "+52", icono: "" },
      ],
      sCountry: null,
      sCountryOrigin: null,
      sCountryTax: null,
      sCountryTaxOrigin: null,
      sCountryPerson: null,
      sCountryPersonOrigin: null,
      aItemsCountry: [],
      aItemsStates: [],
      aItemsStatesTax: [],
      aItemsStatesPerson: [],
      bDisabledState: true,
      bDisabledStateTax: true,
      bDisabledStatePerson: true,

      oEnterprisesOrigin: {},
      //Manager info origin//
      oManagerInfoOrigin: {},
      oManagerInfoContactInfoOrigin: {},
      //End manager info origin//

      oTaxInfoOrigin: {},
      oTaxInfoLocationInfoOrigin: {},
      oTaxInfoStateOrigin: {},
      oTaxInfoCountryOrigin: {},

      //General info origin//
      oGeneralInfoOrigin: {},
      oGeneralContactInfoOrigin: {},
      oGeneralLocationInfoOrigin: {},
      oGeneralStateOrigin: {},
      oGeneralCountryOrigin: {},
      //End general info origin//

      oEnterprises: {},
      //Manager info//
      oManagerInfo: {},
      oManagerInfoContactInfo: {},
      //End manager info//

      oTaxInfo: {},
      oTaxInfoLocationInfo: {},
      oTaxInfoState: {},
      oTaxInfoCountry: {},

      //General info//
      oGeneralInfo: {},
      oGeneralContactInfo: {},
      oGeneralLocationInfo: {},
      oGeneralState: {},
      oGeneralCountry: {},
      //End general info//

      oPerson: {},
      oPersonContactoInfo: {},
      oPersonLocationInfo: {},
      oPersonState: {},
      oPersonCountry: {},

      oPersonOrigin: {},
      oPersonContactoInfoOrigin: {},
      oPersonLocationInfoOrigin: {},
      oPersonStateOrigin: {},
      oPersonCountryOrigin: {},

      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: true,
      },
    };
  },
  beforeMount() {
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {})
      .catch((err) => {
        this.Error(err);
      });
    this.$store.commit("setDialogDelete", {
      active: false,
      textTitle: "",
      textDescription: "",
      api: "",
    });
    this.getCountries();
    if (this.sTabPosition === 0) {
      this.getProviderById();
    } else {
      this.getPersonById();
    }
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      if (this.$refs.searchProvider !== undefined) {
        this.heightMax = this.$refs.searchProvider.clientHeight;
        this.heightMax = this.heightMax + 123; //123 IS NAVBAR
      }
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - this.heightMax;
      } else {
        this.screenHeight = window.innerHeight - this.heightMax;
      }
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE

    //#region GET COUNTRIES
    getCountries() {
      DB.get(`${URI}/api/sp/v1/locations/countries`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aItemsCountry = response.data.results;
          this.aItemsCountry.unshift({
            sCountryId: null,
            sName: "Seleccione un país",
            sCode: null,
          });

          this.aItemsStates.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.aItemsStatesTax.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          this.aItemsStatesPerson.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    //#endregion GET COUNTRIES

    //#region GET STATES
    getState(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          // this.oGeneralState.sStateId = null;
          this.aItemsStates = response.data.results;
          this.aItemsStates.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          // this.validateFormProvider();
          this.bDisabledState = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getStateTax(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          // this.oEnterprises.sTaxLocationStateId = null;
          this.aItemsStatesTax = response.data.results;
          this.aItemsStatesTax.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          // this.validateFormProvider();
          this.bDisabledStateTax = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getStatePerson(id) {
      DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          // this.oPerson.sLocationStateId = null;
          this.aItemsStatesPerson = response.data.results;
          this.aItemsStatesPerson.unshift({
            sStateId: null,
            sCountryId: null,
            sName: "Seleccione un estado",
            sCode: null,
          });
          // this.validateFormPerson();
          this.bDisabledStatePerson = false;
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },

    //#endregion GET STATES

    //#region GET PROVIDER OR PERSON

    getProviderById() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/vendors/enterprises/${this.$route.params.id}`,
        config,
        payload
      )
        .then((response) => {
          this.bModify = false;
          this.fillDataProvider(response);
          this.bUpdateProvider = true;
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    getPersonById() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/vendors/person/${this.$route.params.id}`,
        config,
        payload
      )
        .then((response) => {
          this.bModify = false;
          this.fillDataPerson(response);
          this.bUpdatePerson = true;
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    //#endregion GET PROVIDER OR PERSON

    //#region FILL DATA PROVIDER OR PERSON
    fillDataProvider(response) {
      //FILL IN PROP EDIT

      this.oEnterprises = response.data.results;

      this.oManagerInfo = response.data.results.oManagerInfo;
      this.oManagerInfoContactInfo =
        response.data.results.oManagerInfo.oContactInfo;

      this.oTaxInfo = response.data.results.oTaxInfo;
      this.oTaxInfoLocationInfo = response.data.results.oTaxInfo.oLocationInfo;
      this.oTaxInfoState = response.data.results.oTaxInfo.oLocationInfo.oState;
      this.oTaxInfoCountry =
        response.data.results.oTaxInfo.oLocationInfo.oCountry;
      this.sCountryTax = this.oTaxInfoCountry.sCountryId;

      this.oGeneralInfo = response.data.results.oGeneralInfo;
      this.oGeneralContactInfo =
        response.data.results.oGeneralInfo.oContactInfo;
      this.oGeneralLocationInfo =
        response.data.results.oGeneralInfo.oLocationInfo;
      this.oGeneralState =
        response.data.results.oGeneralInfo.oLocationInfo.oState;
      this.oGeneralCountry =
        response.data.results.oGeneralInfo.oLocationInfo.oCountry;
      this.sCountry = this.oGeneralCountry.sCountryId;

      //FILL IN PROP ORIGIN
      this.oEnterprisesOrigin = JSON.parse(JSON.stringify(this.oEnterprises));

      this.oManagerInfoOrigin = this.parseObj(
        response.data.results.oManagerInfo
      );
      this.oManagerInfoContactInfoOrigin = this.parseObj(
        response.data.results.oManagerInfo.oContactInfo
      );

      this.oTaxInfoOrigin = this.parseObj(response.data.results.oTaxInfo);
      this.oTaxInfoLocationInfoOrigin = this.parseObj(
        response.data.results.oTaxInfo.oLocationInfo
      );
      this.oTaxInfoStateOrigin = this.parseObj(
        response.data.results.oTaxInfo.oLocationInfo.oState
      );
      this.oTaxInfoCountryOrigin = this.parseObj(
        response.data.results.oTaxInfo.oLocationInfo.oCountry
      );
      this.sCountryTaxOrigin = this.oTaxInfoCountryOrigin.sCountryId;

      this.oGeneralInfoOrigin = this.parseObj(
        response.data.results.oGeneralInfo
      );
      this.oGeneralContactInfoOrigin = this.parseObj(
        response.data.results.oGeneralInfo.oContactInfo
      );
      this.oGeneralLocationInfoOrigin = this.parseObj(
        response.data.results.oGeneralInfo.oLocationInfo
      );
      this.oGeneralStateOrigin = this.parseObj(
        response.data.results.oGeneralInfo.oLocationInfo.oState
      );
      this.oGeneralCountryOrigin = this.parseObj(
        response.data.results.oGeneralInfo.oLocationInfo.oCountry
      );
      this.sCountryOrigin = this.oGeneralCountryOrigin.sCountryId;

      this.$store.commit("setsNameDetail", this.oEnterprisesOrigin.sPublicName);
    },
    fillDataPerson(response) {
      this.oPerson = response.data.results;
      this.oPersonContactoInfo = response.data.results.oContactInfo;
      this.oPersonLocationInfo = response.data.results.oLocationInfo;
      this.oPersonState = response.data.results.oLocationInfo.oState;
      this.oPersonCountry = response.data.results.oLocationInfo.oCountry;
      this.sCountryPerson = this.oPersonCountry.sCountryId;

      this.oPersonOrigin = this.parseObj(this.oPerson);
      this.oPersonContactoInfoOrigin = this.parseObj(this.oPersonContactoInfo);
      this.oPersonLocationInfoOrigin = this.parseObj(this.oPersonLocationInfo);
      this.oPersonStateOrigin = this.parseObj(this.oPersonState);
      this.oPersonCountryOrigin = this.parseObj(this.oPersonCountry);
      this.sCountryPersonOrigin = this.oPersonCountryOrigin.sCountryId;
      this.$store.commit("setsNameDetail", this.oPersonOrigin.sFullName);
    },

    //#endregion FILL DATA PROVIDER OR PERSON

    //#region UPDATE PERSON
    changePerson() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminProvider) {
            this.updatePerson();
          } else {
            this.Error(this.$store.state.oError403);
            this.discardChangePerson();
            this.bLoading = false;
          }
        })
        .catch((err) => {
          this.Error(err);
          this.bLoading = false;
        });
    },

    updatePerson() {
      const payload = {
          sFullName: this.oPerson.sFullName,
          sEmail: this.oPerson.sEmail,
          sCountryCallingCode: this.oPersonContactoInfo.sCountryCallingCode,
          sAreaCallingCode: this.oPersonContactoInfo.sAreaCallingCode,
          sPhoneNumber: this.oPersonContactoInfo.sPhoneNumber,
          sPhoneExtension: this.oPersonContactoInfo.sPhoneExtension,
          sLocationStateId: this.oPersonState.sStateId,
          sLocationCityName: this.oPersonLocationInfo.sCityName,
          sZipCode: this.oPersonLocationInfo.sZipCode,
          sAddress: this.oPersonLocationInfo.sAddress,
          sOptionalAddress: this.oPersonLocationInfo.sOptionalAddress,
        },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.put(
        `${URI}/api/sp/v1/vendors/person/${this.$route.params.id}`,
        payload,
        config
      )
        .then((response) => {
          this.Success(response.data.message);
          this.getPersonById();
          this.bLoading = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },

    //#endregion UPDATE PROVIDER OR PERSON

    //#region DELETE PROVIDER OR PRESON
    deleteItem(sName) {
      // let sNameType = this.sTabPosition === 0 ? "empresa" : "persona física";
      // let sNameTypeApi = this.sTabPosition === 0 ? "enterprises" : "person";

      // this.$store.commit("setDialogDelete", {
      //   active: true,
      //   textTitle: `Eliminar ${sNameType}`,
      //   textDescription: `La eliminación de una ${sNameType} es una acción irreversible.`,
      //   textQuestion:
      //     `Acepto la responsabilidad y deseo proseguir con la eliminación de la  ${sNameType}: ` +
      //     sName +
      //     ".",
      //   returnToView: true,

      //   api: `${URI}/api/sp/v1/vendors/${sNameTypeApi}/${this.$route.params.id}`,
      // });
      
      if (sName) {
        let sNameType = this.sTabPosition === 0 ? "empresa" : "persona física";
        let sNameTypeApi = this.sTabPosition === 0 ? "enterprises" : "person";

        this.oDialogDelete.textTitle = `Eliminar ${sNameType}`;
        this.oDialogDelete.textDescription = `La eliminación de una ${sNameType} es una acción irreversible.`;
        this.oDialogDelete.textQuestion =
          `Acepto la responsabilidad y deseo proseguir con la eliminación de la  ${sNameType}: ` +
          sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/vendors/${sNameTypeApi}/${this.$route.params.id}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    //#endregion DELETE PROVIDER OR PRESON

    //#region UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)
    updateCountryCallingCode(val) {
      this.oGeneralContactInfo.sCountryCallingCode = val;
      this.validateFormProvider();
    },
    updateAreaCallingCode(val) {
      this.oGeneralContactInfo.sAreaCallingCode = val;
      this.validateFormProvider();
    },
    updatePhoneNumber(val) {
      this.oGeneralContactInfo.sPhoneNumber = val;
      this.validateFormProvider();
    },
    updatePhoneExtension(val) {
      this.oGeneralContactInfo.sExtension = val;
      this.validateFormProvider();
    },
    //#endregion UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)

    //#region UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)
    updateManagerCountryCallingCode(val) {
      this.oManagerInfoContactInfo.sCountryCallingCode = val;
      this.validateFormProvider();
    },
    updateManagerAreaCallingCode(val) {
      this.oManagerInfoContactInfo.sAreaCallingcode = val;
      this.validateFormProvider();
    },
    updateManagerPhoneNumber(val) {
      this.oManagerInfoContactInfo.sPhoneNumber = val;
      this.validateFormProvider();
    },
    updateManagerPhoneExtension(val) {
      this.oManagerInfoContactInfo.sExtension = val;
      this.validateFormProvider();
    },
    //#endregion UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)

    //#region UPDATE DATE PHONE PROVIDER (PERSON SECTION)
    updatePersonCountryCallingCode(val) {
      this.oPersonContactoInfo.sCountryCallingCode = val;
      this.validateFormPerson();
    },
    updatePersonAreaCallingCode(val) {
      this.oPersonContactoInfo.sAreaCallingCode = val;
      this.validateFormPerson();
    },
    updatePersonPhoneNumber(val) {
      this.oPersonContactoInfo.sPhoneNumber = val;
      this.validateFormPerson();
    },
    updatePersonPhoneExtension(val) {
      this.oPersonContactoInfo.sPhoneExtension = val;
      this.validateFormPerson();
    },
    //#endregion UPDATE DATE PHONE PROVIDER (PERSON SECTION)

    //#region DISCARD CHANGES PROVIDER OR PERSON

    discardChangeProvider() {
      this.bModify = false;

      this.oEnterprises = this.parseObj(this.oEnterprisesOrigin);

      this.oManagerInfo = this.parseObj(this.oManagerInfoOrigin);
      this.oManagerInfoContactInfo = this.parseObj(
        this.oManagerInfoContactInfoOrigin
      );

      this.oTaxInfo = this.parseObj(this.oTaxInfoOrigin);
      this.oTaxInfoLocationInfo = this.parseObj(
        this.oTaxInfoLocationInfoOrigin
      );
      this.oTaxInfoState = this.parseObj(this.oTaxInfoStateOrigin);
      this.oTaxInfoCountry = this.parseObj(this.oTaxInfoCountryOrigin);
      this.sCountryTax = this.sCountryTaxOrigin;

      this.oGeneralInfo = this.parseObj(this.oGeneralInfoOrigin);
      this.oGeneralContactInfo = this.parseObj(this.oGeneralContactInfoOrigin);
      this.oGeneralLocationInfo = this.parseObj(
        this.oGeneralLocationInfoOrigin
      );
      this.oGeneralState = this.parseObj(this.oGeneralStateOrigin);
      this.oGeneralCountry = this.parseObj(this.oGeneralCountryOrigin);
      this.sCountry = this.sCountryOrigin;
    },
    discardChangePerson() {
      this.bModify = false;

      this.oPerson = this.parseObj(this.oPersonOrigin);
      this.oPersonContactoInfo = this.parseObj(this.oPersonContactoInfoOrigin);
      this.oPersonLocationInfo = this.parseObj(this.oPersonLocationInfoOrigin);
      this.oPersonState = this.parseObj(this.oPersonStateOrigin);
      // this.oPersonCountry= this.parseObj(this.oPersonCountryOrigin);
      this.sCountryPerson = this.sCountryPersonOrigin;
      this.bUpdatePerson = true;
    },
    //#endregion DISCARD CHANGES PROVIDER OR PERSON

    //#region VALIDATE FORM PROVIDER OR PERSON
    validateFormProvider() {
      this.bUpdateProvider =
        (this.oEnterprises.sPublicName == "" ||
          this.oEnterprises.sPublicName ==
            this.oEnterprisesOrigin.sPublicName) &&
        (this.oGeneralInfo.sEmail == "" ||
          this.oGeneralInfo.sEmail ==
            this.oEnterprisesOrigin.oGeneralInfo.sEmail) &&
        (this.oGeneralContactInfo.sCountryCallingCode == "" ||
          this.oGeneralContactInfo.sCountryCallingCode ==
            this.oEnterprisesOrigin.oGeneralInfo.oContactInfo
              .sCountryCallingCode) &&
        (this.oGeneralContactInfo.sAreaCallingCode == "" ||
          this.oGeneralContactInfo.sAreaCallingCode ==
            this.oEnterprisesOrigin.oGeneralInfo.oContactInfo
              .sAreaCallingCode) &&
        (this.oGeneralContactInfo.sPhoneNumber == "" ||
          this.oGeneralContactInfo.sPhoneNumber ==
            this.oEnterprisesOrigin.oGeneralInfo.oContactInfo.sPhoneNumber) &&
        this.oGeneralContactInfo.sExtension ==
          this.oEnterprisesOrigin.oGeneralInfo.oContactInfo.sExtension &&
        (this.sCountry == null || this.sCountry == this.sCountryOrigin) &&
        (this.oGeneralState.sStateId == null ||
          this.oGeneralState.sStateId ==
            this.oEnterprisesOrigin.oGeneralInfo.oLocationInfo.oState
              .sStateId) &&
        (this.oGeneralLocationInfo.sCityName == "" ||
          this.oGeneralLocationInfo.sCityName ==
            this.oEnterprisesOrigin.oGeneralInfo.oLocationInfo.sCityName) &&
        (this.oGeneralLocationInfo.sZipCode == "" ||
          this.oGeneralLocationInfo.sZipCode ==
            this.oEnterprisesOrigin.oGeneralInfo.oLocationInfo.sZipCode) &&
        (this.oGeneralLocationInfo.sAddress == "" ||
          this.oGeneralLocationInfo.sAddress ==
            this.oEnterprisesOrigin.oGeneralInfo.oLocationInfo.sAddress) &&
        this.oGeneralLocationInfo.sOptionalAddress ==
          this.oEnterprisesOrigin.oGeneralInfo.oLocationInfo.sOptionalAddress &&
        (this.oManagerInfo.sFullName == "" ||
          this.oManagerInfo.sFullName ==
            this.oEnterprisesOrigin.oManagerInfo.sFullName) &&
        (this.oManagerInfo.sEmail == "" ||
          this.oManagerInfo.sEmail ==
            this.oEnterprisesOrigin.oManagerInfo.sEmail) &&
        (this.oManagerInfoContactInfo.sCountryCallingCode == "" ||
          this.oManagerInfoContactInfo.sCountryCallingCode ==
            this.oEnterprisesOrigin.oManagerInfo.oContactInfo
              .sCountryCallingCode) &&
        (this.oManagerInfoContactInfo.sAreaCallingcode == "" ||
          this.oManagerInfoContactInfo.sAreaCallingcode ==
            this.oEnterprisesOrigin.oManagerInfo.oContactInfo
              .sAreaCallingcode) &&
        (this.oManagerInfoContactInfo.sPhoneNumber == "" ||
          this.oManagerInfoContactInfo.sPhoneNumber ==
            this.oEnterprisesOrigin.oManagerInfo.oContactInfo.sPhoneNumber) &&
        this.oManagerInfoContactInfo.sExtension ==
          this.oEnterprisesOrigin.oManagerInfo.oContactInfo.sExtension &&
        (this.oEnterprises.sBussinessName == "" ||
          this.oEnterprises.sBussinessName ==
            this.oEnterprisesOrigin.sBussinessName) &&
        (this.oTaxInfo.sEmail == "" ||
          this.oTaxInfo.sEmail == this.oEnterprisesOrigin.oTaxInfo.sEmail) &&
        (this.oTaxInfo.sTaxId == "" ||
          this.oTaxInfo.sTaxId == this.oEnterprisesOrigin.oTaxInfo.sTaxId) &&
        (this.sCountryTax == null ||
          this.sCountryTax == this.sCountryTaxOrigin) &&
        (this.oTaxInfoState.sStateId == null ||
          this.oTaxInfoState.sStateId ==
            this.oEnterprisesOrigin.oTaxInfo.oLocationInfo.oState.sStateId) &&
        (this.oTaxInfoLocationInfo.sCityName == "" ||
          this.oTaxInfoLocationInfo.sCityName ==
            this.oEnterprisesOrigin.oTaxInfo.oLocationInfo.sCityName) &&
        (this.oTaxInfo.sZipCode == "" ||
          this.oTaxInfo.sZipCode ==
            this.oEnterprisesOrigin.oTaxInfo.sZipCode) &&
        (this.oTaxInfoLocationInfo.sAddress == "" ||
          this.oTaxInfoLocationInfo.sAddress ==
            this.oEnterprisesOrigin.oTaxInfo.oLocationInfo.sAddress) &&
        this.oTaxInfoLocationInfo.sOptionalAddress ==
          this.oEnterprisesOrigin.oTaxInfo.oLocationInfo.sOptionalAddress;
    },
    validateFormPerson() {
      this.bUpdatePerson =
        (this.withTrim(this.oPerson.sFullName) == "" ||
          this.withTrim(this.oPerson.sFullName) ==
            this.oPersonOrigin.sFullName) &&
        this.withTrim(this.oPerson.sEmail) == this.oPersonOrigin.sEmail &&
        this.oPersonContactoInfo.sCountryCallingCode ==
          this.oPersonContactoInfoOrigin.sCountryCallingCode &&
        this.withTrim(this.oPersonContactoInfo.sAreaCallingCode) ==
          this.oPersonContactoInfoOrigin.sAreaCallingCode &&
        this.withTrim(this.oPersonContactoInfo.sPhoneNumber) ==
          this.oPersonContactoInfoOrigin.sPhoneNumber &&
        this.withTrim(this.oPersonContactoInfo.sPhoneExtension) ==
          this.oPersonContactoInfoOrigin.sPhoneExtension &&
        this.sCountryPerson == this.sCountryPersonOrigin &&
        this.oPersonState.sStateId == this.oPersonStateOrigin.sStateId &&
        this.withTrim(this.oPersonLocationInfo.sCityName) ==
          this.oPersonLocationInfoOrigin.sCityName &&
        this.withTrim(this.oPersonLocationInfo.sZipCode) ==
          this.oPersonLocationInfoOrigin.sZipCode &&
        this.withTrim(this.oPersonLocationInfo.sAddress) ==
          this.oPersonLocationInfoOrigin.sAddress &&
        this.withTrim(this.oPersonLocationInfo.sOptionalAddress) ==
          this.oPersonLocationInfoOrigin.sOptionalAddress;
    },
    //#endregion VALIDATE FORM PROVIDER OR PERSON

    //#region FUNCTIONS DYNAMIC

    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    noLetterZipCodeInformation() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oGeneralLocationInfo.sZipCode);
        if (result == null) {
          this.oGeneralLocationInfo.sZipCode = "";
        } else {
          this.oGeneralLocationInfo.sZipCode =
            this.oGeneralLocationInfo.sZipCode;
        }
      }, 100);
    },
    noLetterZipCodeTax() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oTaxInfo.sZipCode);
        if (result == null) {
          this.oTaxInfo.sZipCode = "";
        } else {
          this.oTaxInfo.sZipCode = this.oTaxInfo.sZipCode;
        }
      }, 100);
    },
    noLetterZipCodePerson() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.oPersonLocationInfo.sZipCode);
        if (result == null) {
          this.oPersonLocationInfo.sZipCode = "";
        } else {
          this.oPersonLocationInfo.sZipCode = this.oPersonLocationInfo.sZipCode;
        }
      }, 100);
    },

    withTrim(val) {
      return val !== null ? val.trim() : val;
    },
    parseObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    //#endregion FUNCTIONS DYNAMIC
  },
  computed: {
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    // bAdminProvider() {
    //   return this.$store.state.bAdminProvider;
    // },
  },
  watch: {
    sCountry() {
      if (this.sCountry !== null) {
        this.getState(this.sCountry);
      } else {
        this.bDisabledState = true;
        this.oGeneralState.sStateId = null;
        this.aItemsStates = [];
        this.aItemsStates.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        this.validateFormProvider();
      }
    },
    sCountryTax() {
      if (this.sCountryTax !== null) {
        this.getStateTax(this.sCountryTax);
      } else {
        this.bDisabledStateTax = true;
        this.oTaxInfoState.sStateId = null;
        this.aItemsStatesTax = [];
        this.aItemsStatesTax.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        this.validateFormProvider();
      }
    },
    sCountryPerson() {
      if (this.sCountryPerson !== null) {
        this.getStatePerson(this.sCountryPerson);
      } else {
        this.bDisabledStatePerson = true;
        this.oPerson.sLocationStateId = null;
        this.oPersonState.sStateId = null;

        this.aItemsStatesPerson = [];
        this.aItemsStatesPerson.unshift({
          sStateId: null,
          sCountryId: null,
          sName: "Seleccione un estado",
          sCode: null,
        });
        // this.validateFormPerson();
      }
    },
    bAdminProvider() {
      if (this.sTabPosition == 0) {
        this.discardChangeProvider();
      } else {
        this.discardChangePerson();
      }
    },
  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}
.content-btn-primary {
  width: 200px;
}
.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}
/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}
/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>